import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PhoneIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.85026 5.3173C3.88741 4.53978 4.3504 3.92512 4.90265 3.53146L5.90968 2.81361C6.64028 2.29281 7.65473 2.46269 8.1758 3.19311L9.35955 4.85245C9.88076 5.58306 9.711 6.59785 8.9804 7.11906L8.13683 7.72085C8.13501 7.72214 8.13357 7.72337 8.13243 7.72446C8.29918 8.2415 8.6954 9.26863 9.47956 10.3678C10.2637 11.467 11.106 12.176 11.5406 12.5019C11.542 12.5012 11.5436 12.5002 11.5455 12.4989L12.389 11.8971C13.1196 11.3759 14.1344 11.5457 14.6556 12.2763L15.8708 13.9797C16.3922 14.7105 16.2222 15.7256 15.4912 16.2467L14.5199 16.939C13.9433 17.3501 13.1787 17.5842 12.4109 17.3431C11.081 16.9256 8.66672 15.7563 6.37108 12.5383C4.06421 9.30464 3.78519 6.67949 3.85026 5.3173ZM5.53147 4.41361C5.16274 4.67645 4.94919 5.01678 4.93236 5.36899C4.87824 6.50196 5.09521 8.88445 7.25299 11.9092C9.40094 14.9201 11.6119 15.9568 12.7354 16.3095C13.0926 16.4216 13.5075 16.3303 13.8911 16.0569L14.8624 15.3645C15.106 15.1908 15.1627 14.8525 14.9889 14.6089L13.7737 12.9054C13.6 12.6619 13.2617 12.6053 13.0182 12.7791L12.1746 13.3808C11.8087 13.6419 11.2959 13.6714 10.9022 13.3772C10.4094 13.009 9.47075 12.2209 8.59765 10.997C7.72454 9.7731 7.28478 8.62907 7.09698 8.04328C6.94694 7.57523 7.14175 7.09998 7.50768 6.83893L8.35125 6.23714C8.59478 6.06341 8.65137 5.72514 8.47763 5.4816L7.29388 3.82226C7.12019 3.57879 6.78204 3.52216 6.5385 3.69576L5.53147 4.41361Z"
        fill={color}
      />
    </svg>
  );
};

const PhoneIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.17572 3.19312C7.65465 2.4627 6.64021 2.29282 5.9096 2.81362L4.90257 3.53147C4.35032 3.92513 3.88733 4.53979 3.85019 5.31731C3.78512 6.67951 4.06414 9.30465 6.371 12.5383C8.66665 15.7563 11.081 16.9256 12.4108 17.3431C13.1786 17.5842 13.9432 17.3501 14.5199 16.939L15.4911 16.2467C16.2221 15.7256 16.3921 14.7105 15.8708 13.9797L14.6556 12.2763C14.1344 11.5457 13.1196 11.3759 12.389 11.8972L11.5454 12.4989C11.5436 12.5002 11.5405 12.5019 11.5405 12.5019C11.1059 12.176 10.2636 11.4671 9.47949 10.3678C8.69533 9.26864 8.29911 8.24151 8.13236 7.72447C8.13236 7.72447 8.13494 7.72216 8.13676 7.72086L8.98033 7.11907C9.71093 6.59786 9.88068 5.58307 9.35948 4.85246L8.17572 3.19312Z"
        fill={color}
      />
    </svg>
  );
};

export const PhoneIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PhoneIconOutlined}
      solidSvg={PhoneIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
