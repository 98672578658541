import clsx from 'clsx';
import React from 'react';
import { CloseIcon, LockIcon, TimeIcon } from '../../icons';
import { ArchiveIcon } from '../../icons';
import classes from './avatar.module.scss';
import { colorTextAvatar } from './colorTextAvatar';

type TAvatar = {
  email?: string;
  name?: string;
  fname?: string;
  lname?: string;
  colorAvatar?: string;
  hasBorder?: boolean;
  type?: 'circle' | 'square';
  iconType?: 'archived' | 'none' | 'lock';
  size?: 20 | 24 | 40;
  action?: () => void;
  classProps?: string;
};

const getLetters = (fname?: string, lname?: string, email?: string) => {
  if (fname && !lname) return fname?.charAt(0).toUpperCase();
  if (!fname && lname) return lname?.charAt(0).toUpperCase();
  if (fname && lname) return `${fname?.charAt(0).toUpperCase()}${lname?.charAt(0).toUpperCase()}`;
  if (email) return email.charAt(0).toUpperCase();
  return <TimeIcon color="blocked" size={20} />;
};

const getConvertStr = (str: string) => {
  return str
    .replace(/[^a-zа-яё0-9 ]/gi, '')
    .replace(/\s+/g, ' ') // для уберания лишних пробелов после уберания лишних знаков
    .trim();
};

const getConvertName = (str: string, email?: string) => {
  const strArr = str.split(' ');
  const name = getConvertStr(str).split(' ');
  return getConvertStr(str) ? getLetters(name[0], name[1], email) : getLetters(strArr[0], strArr[1] || str[1], email);
};

export const Avatar = ({
  name,
  email,
  fname,
  lname,
  colorAvatar,
  hasBorder,
  type = 'circle',
  iconType,
  size = 24,
  action,
  classProps,
}: TAvatar) => {
  const BAvatar = colorAvatar;
  const CAvatar = colorAvatar && colorTextAvatar(colorAvatar);
  const convertFname = fname && getConvertStr(fname);
  const convertLname = lname && getConvertStr(lname);
  const isAvatarPropsAvailable = BAvatar && CAvatar;
  const styles: React.CSSProperties | undefined = isAvatarPropsAvailable
    ? {
        background: `#${BAvatar}`,
        color: `#${CAvatar}`,
      }
    : undefined;
  return (
    <div
      style={styles}
      className={clsx(
        classes.profileAvatar,
        hasBorder && classes.bordered,
        type === 'square' && classes.squared,
        size === 20 && classes.size20,
        size === 40 && classes.size40,
        size === 40 && iconType && classes.disabledBG,
        action && classes.isClickable,
        classProps,
        (iconType || !isAvatarPropsAvailable) && classes.iconType,
      )}
      onClick={action}
    >
      {iconType === 'none' && <CloseIcon color={size === 40 ? 'white' : 'blocked'} size={size === 40 ? 16 : 14} />}
      {iconType === 'archived' && (
        <ArchiveIcon color={size === 40 ? 'white' : 'blocked'} size={size === 40 ? 20 : 16} />
      )}
      {iconType === 'lock' && <LockIcon color={size === 40 ? 'white' : 'blocked'} size={size === 40 ? 20 : 16} />}
      {!iconType &&
        (name ? getConvertName(name, email) : getLetters(convertFname || fname, convertLname || convertLname, email))}
    </div>
  );
};

type TSmashedAvatar = {
  amount: number;
  disabled?: boolean;
  hasBorder?: boolean;
  type?: 'circle' | 'square';
  action?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const SmashedAvatar = ({ amount, disabled, hasBorder, type = 'circle', action }: TSmashedAvatar) => {
  return (
    <div
      ref={ref => {
        if (!ref) return;
        ref.onclick = event => {
          event.stopPropagation();
          // @ts-ignore
          action && action(event);
        };
      }}
      className={clsx(
        classes.smashedAvatar,
        classes.textPrimary,
        disabled && classes.disabled,
        hasBorder && classes.bordered,
        type === 'square' && classes.squared,
        action && classes.isClickable,
      )}
    >
      {String(amount).length < 3 ? `+${String(amount)}` : '99+'}
    </div>
  );
};
