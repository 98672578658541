import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';
import classes from './style.module.scss';

export const OutLinedFolder = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7 11.491V7.98517H14.7525C13.7047 7.98517 12.6931 7.60265 11.9075 6.90946L10.5809 5.73897C10.2703 5.46492 9.87038 5.3137 9.45617 5.31369L5.3 5.31367V8.81952C4.58203 8.81952 4 9.40155 4 10.1195V5.31367C4 4.5957 4.58203 4.01367 5.30001 4.01367L9.45618 4.01369C10.1871 4.0137 10.8929 4.28057 11.441 4.76419L12.7676 5.93468C13.3157 6.4183 14.0215 6.68517 14.7525 6.68517H18.7C19.418 6.68517 20 7.2672 20 7.98517V12.791C20 12.791 20 12.791 20 12.791V18.6039C20 19.3218 19.418 19.9039 18.7 19.9039H5.3C4.58203 19.9039 4 19.3218 4 18.6039V10.1195C4 9.40155 4.58203 8.81952 5.3 8.81952L8.83805 8.81956C9.569 8.81957 10.2748 9.08644 10.8229 9.57005L12.1494 10.7405C12.6975 11.2242 13.4034 11.491 14.1343 11.491L18.7 11.491ZM18.7 12.791V18.6039H5.3V10.1195L8.83804 10.1196C9.25224 10.1196 9.6522 10.2708 9.96279 10.5448L11.2893 11.7153C12.0749 12.4085 13.0866 12.791 14.1343 12.791H18.7Z"
        fill={color}
      />
    </svg>
  );
};

export const SolidFolder = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 18.6038C20 19.3218 19.418 19.9038 18.7 19.9038H5.3C4.58203 19.9038 4 19.3218 4 18.6038V5.31367C4 4.5957 4.58203 4.01367 5.30001 4.01367L9.45618 4.01369C10.1871 4.0137 10.8929 4.28057 11.441 4.76419L12.7676 5.93468C13.3157 6.4183 14.0215 6.68517 14.7525 6.68517H18.7C19.418 6.68517 20 7.2672 20 7.98517V18.6038ZM5.3 8.81951C5.3 8.81951 5.3 8.81951 5.3 8.81951L8.83805 8.81953C9.569 8.81953 10.2748 9.08641 10.8229 9.57002L12.1494 10.7405C12.6975 11.2241 13.4034 11.491 14.1343 11.491H18.7V7.98517H14.7525C13.7047 7.98517 12.6931 7.60265 11.9075 6.90946L10.5809 5.73897C10.2703 5.46492 9.87038 5.3137 9.45617 5.31369L5.3 5.31367V8.81951Z"
        fill={color}
      />
    </svg>
  );
};

export const FolderIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={OutLinedFolder}
      solidSvg={SolidFolder}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};

export const FolderIconColored = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={OutLinedFolder}
      solidSvg={SolidFolder}
      size={size}
      color="white"
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={`${classes.iconSpacesColored} ${className || ''}`}
    />
  );
};
