import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const InfoOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.34998C12.4142 7.34998 12.75 7.68576 12.75 8.09998C12.75 8.51419 12.4142 8.84998 12 8.84998C11.5858 8.84998 11.25 8.51419 11.25 8.09998C11.25 7.68576 11.5858 7.34998 12 7.34998Z"
        fill={color}
      />
      <path
        d="M12 10.2038C12.359 10.2038 12.65 10.4948 12.65 10.8538V14.7C12.65 15.059 12.941 15.35 13.3 15.35L13.35 15.35C13.709 15.35 14 15.641 14 16C14 16.359 13.709 16.65 13.35 16.65L13.2999 16.65C12.223 16.65 11.35 15.7769 11.35 14.7V10.8538C11.35 10.4948 11.641 10.2038 12 10.2038Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM4.3 12C4.3 7.74741 7.74741 4.3 12 4.3C16.2526 4.3 19.7 7.74741 19.7 12C19.7 16.2526 16.2526 19.7 12 19.7C7.74741 19.7 4.3 16.2526 4.3 12Z"
        fill={color}
      />
    </svg>
  );
};

const InfoSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 7.25C11.5306 7.25 11.15 7.63056 11.15 8.1C11.15 8.56944 11.5306 8.95 12 8.95C12.4694 8.95 12.85 8.56944 12.85 8.1C12.85 7.63056 12.4694 7.25 12 7.25ZM14.1 16C14.1 16.4142 13.7642 16.75 13.35 16.75H13.3C12.1678 16.75 11.25 15.8322 11.25 14.7V10.95C11.25 10.5358 11.5858 10.2 12 10.2C12.4142 10.2 12.75 10.5358 12.75 10.95V14.7C12.75 15.0038 12.9962 15.25 13.3 15.25H13.35C13.7642 15.25 14.1 15.5858 14.1 16Z"
        fill={color}
      />
    </svg>
  );
};

export const InfoIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={InfoOutlined}
      solidSvg={InfoSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
