import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';
import classes from './style.module.scss';

export const LayoutOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8386 7.9927C19.4914 7.92349 20 7.37114 20 6.7V5.3C20 4.58203 19.418 4 18.7 4H5.3C4.58203 4 4 4.58203 4 5.3V6.7C4 7.37114 4.50858 7.9235 5.16137 7.9927C5.20692 7.99752 5.25317 8 5.3 8H18.7C18.7468 8 18.7931 7.99752 18.8386 7.9927ZM7.57719 9.3C7.52175 9.29278 7.4652 9.28906 7.40779 9.28906H5.3C5.25317 9.28906 5.20692 9.29154 5.16137 9.29637C4.50858 9.36557 4 9.91792 4 10.5891V18.7C4 19.418 4.58203 20 5.3 20H7.40779C8.0863 20 8.64341 19.4802 8.70259 18.8171C8.70603 18.7785 8.70779 18.7395 8.70779 18.7V10.5891C8.70779 10.5496 8.70603 10.5106 8.70259 10.472C8.64842 9.86499 8.17702 9.37806 7.57719 9.3ZM18.8386 9.29637C18.7931 9.29154 18.7468 9.28906 18.7 9.28906H11.3C11.2426 9.28906 11.186 9.29278 11.1306 9.3C10.5308 9.37806 10.0594 9.86499 10.0052 10.472C10.0018 10.5106 10 10.5496 10 10.5891V18.7C10 18.7395 10.0018 18.7785 10.0052 18.8171C10.0644 19.4802 10.6215 20 11.3 20H18.7C19.418 20 20 19.418 20 18.7L20 10.5891C20 9.91792 19.4914 9.36557 18.8386 9.29637ZM5.6 5.3C5.43431 5.3 5.3 5.43431 5.3 5.6V6.4C5.3 6.56569 5.43432 6.7 5.6 6.7L18.4 6.7C18.5657 6.7 18.7 6.56569 18.7 6.4L18.7 5.6C18.7 5.43432 18.5657 5.3 18.4 5.3L5.6 5.3ZM5.6 10.5891C5.43431 10.5891 5.3 10.7234 5.3 10.8891V18.4C5.3 18.5657 5.43431 18.7 5.6 18.7L7.10779 18.7C7.27347 18.7 7.40779 18.5657 7.40779 18.4L7.40779 10.8891C7.40779 10.7234 7.27347 10.5891 7.10779 10.5891H5.6ZM11.6 10.5891C11.4343 10.5891 11.3 10.7234 11.3 10.8891V18.4C11.3 18.5657 11.4343 18.7 11.6 18.7L18.4 18.7C18.5657 18.7 18.7 18.5657 18.7 18.4L18.7 10.8891C18.7 10.7234 18.5657 10.5891 18.4 10.5891H11.6Z"
        fill={color}
      />
    </svg>
  );
};

const LayoutSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.3 4C4.58203 4 4 4.58203 4 5.3V6.7C4 7.41797 4.58203 8 5.3 8H18.7C19.418 8 20 7.41797 20 6.7V5.3C20 4.58203 19.418 4 18.7 4H5.3Z"
        fill={color}
      />
      <path
        d="M5.3 9.28906C4.58203 9.28906 4 9.87109 4 10.5891L4 18.7C4 19.418 4.58203 20 5.3 20H7.40779C8.12576 20 8.70779 19.418 8.70779 18.7V10.5891C8.70779 9.87109 8.12576 9.28906 7.40779 9.28906H5.3Z"
        fill={color}
      />
      <path
        d="M11.3 9.28906C10.582 9.28906 10 9.87109 10 10.5891V18.7C10 19.418 10.582 20 11.3 20H18.7C19.418 20 20 19.418 20 18.7L20 10.5891C20 9.87109 19.418 9.28906 18.7 9.28906H11.3Z"
        fill={color}
      />
    </svg>
  );
};

export const LayoutIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={LayoutOutlined}
      solidSvg={LayoutSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};

export const LayoutIconColored = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={LayoutOutlined}
      solidSvg={LayoutSolid}
      size={size}
      color="white"
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={`${classes.iconReportColored} ${className || ''}`}
    />
  );
};
