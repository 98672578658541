import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

type IconStatusProps = {
  status: string;
  className?: string;
};

// TODO сделать список возможных статусов и типизировать
const activeStatuses = ['Активн', 'Запущен', 'active'];
const stopStatuses = ['Остан', 'Ожидает подтверждения', 'completed', 'Данные отсутствуют', 'Черновик'];
const archiveStatuses = ['Архивн', 'blocked', 'archived', 'В архиве'];
const errorStatuses = ['Ошиб', 'error', 'banned', 'Отклонено'];

export const IconStatus = ({ status, className }: IconStatusProps) => {
  if (!status || typeof status !== 'string') {
    return null;
  }
  return (
    <span
      className={clsx(
        className,
        classes.statusIcon,
        activeStatuses.some(s => status.includes(s)) && classes.activeStatus,
        stopStatuses.some(s => status.includes(s)) && classes.stopStatus,
        archiveStatuses.some(s => status.includes(s)) && classes.archiveStatus,
        errorStatuses.some(s => status.includes(s)) && classes.errorStatus,
      )}
    ></span>
  );
};
