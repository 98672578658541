import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const AutomationIconOutlined = ({ color = '#626F84', size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76832 4.60862L3.739 3.80929C3.55373 3.76041 3.3846 3.92954 3.43349 4.1148L4.23282 7.14412L2.53649 9.77818C2.43275 9.93927 2.54134 10.1524 2.73264 10.1631L5.86071 10.339L7.84165 12.7663C7.96279 12.9148 8.19904 12.8773 8.26838 12.6987L9.17137 10.3729L13.6122 14.8138C13.7294 14.9309 13.9193 14.9309 14.0365 14.8138L14.3783 14.472C14.4954 14.3548 14.4954 14.1649 14.3783 14.0477L9.9111 9.58057L12.3229 8.64419C12.5015 8.57484 12.5389 8.3386 12.3905 8.21745L9.96324 6.23651L9.78734 3.10845C9.77658 2.91714 9.56346 2.80855 9.40238 2.9123L6.76832 4.60862ZM6.95352 5.7779L8.78604 4.59776L8.90842 6.77397L10.5971 8.15212L8.56519 8.941L7.77632 10.9729L6.39817 9.28422L4.22195 9.16185L5.4021 7.32932L4.846 5.2218L6.95352 5.7779Z"
        fill={color}
      />
      <path
        d="M14.8007 16.0023C14.6836 15.8851 14.6836 15.6951 14.8007 15.578L15.1425 15.2362C15.2596 15.1191 15.4496 15.1191 15.5668 15.2362L16.7851 16.4546C16.9967 16.6661 16.9967 17.0091 16.7851 17.2206C16.5736 17.4322 16.2306 17.4322 16.0191 17.2206L14.8007 16.0023Z"
        fill={color}
      />
    </svg>
  );
};

export const AutomationIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={AutomationIconOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
