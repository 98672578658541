import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const UnLockIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.65 13.6417C12.65 13.2827 12.359 12.9917 12 12.9917C11.641 12.9917 11.35 13.2827 11.35 13.6417V15.3582C11.35 15.7172 11.641 16.0082 12 16.0082C12.359 16.0082 12.65 15.7172 12.65 15.3582V13.6417Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0072 4.42695C15.5225 4.42695 14.3188 5.63058 14.3188 7.11534L14.3188 8.99998H16C17.6569 8.99998 19 10.3431 19 12V17C19 18.6568 17.6569 20 16 20H8C6.34315 20 5 18.6568 5 17V12C5 10.3431 6.34315 8.99998 8 8.99998H13.0188L13.0188 7.11534C13.0188 4.91261 14.8045 3.12695 17.0072 3.12695C19.21 3.12695 20.9956 4.91261 20.9956 7.11534V7.19509C20.9956 7.55408 20.7046 7.84509 20.3456 7.84509C19.9866 7.84509 19.6956 7.55408 19.6956 7.19509V7.11534C19.6956 5.63058 18.492 4.42695 17.0072 4.42695ZM16 10.3H8C7.06112 10.3 6.3 11.0611 6.3 12V17C6.3 17.9389 7.06112 18.7 8 18.7H16C16.9389 18.7 17.7 17.9389 17.7 17V12C17.7 11.0611 16.9389 10.3 16 10.3Z"
        fill={color}
      />
    </svg>
  );
};

const UnLockIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3188 7.11534C14.3188 5.63058 15.5225 4.42695 17.0072 4.42695C18.492 4.42695 19.6956 5.63058 19.6956 7.11534V7.19509C19.6956 7.55408 19.9866 7.84509 20.3456 7.84509C20.7046 7.84509 20.9956 7.55408 20.9956 7.19509V7.11534C20.9956 4.91261 19.21 3.12695 17.0072 3.12695C14.8045 3.12695 13.0188 4.91261 13.0188 7.11534L13.0188 8.99998H8C6.34315 8.99998 5 10.3431 5 12V17C5 18.6568 6.34315 20 8 20H16C17.6569 20 19 18.6568 19 17V12C19 10.3431 17.6569 8.99998 16 8.99998H14.3188L14.3188 7.11534ZM12 12.9917C12.359 12.9917 12.65 13.2827 12.65 13.6417V15.3582C12.65 15.7172 12.359 16.0082 12 16.0082C11.641 16.0082 11.35 15.7172 11.35 15.3582V13.6417C11.35 13.2827 11.641 12.9917 12 12.9917Z"
        fill={color}
      />
    </svg>
  );
};

export const UnLockIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={UnLockIconOutlined}
      solidSvg={UnLockIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
