import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from '../icons-kit/_utils';

const AppsflyerIconCommon = ({ color, size }: TSvgProps) => {
  return (
    <div
      style={{
        width: `${size}`,
        height: `${size}`,
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.08959 6.35072L4.13994 12.0014C4.33997 12.4014 4.84002 12.7014 5.29008 12.7014H11.4908C11.8908 12.7014 12.0909 12.4014 11.8908 12.0014L8.84049 6.35072C8.64046 5.95067 8.1404 5.65063 7.69035 5.65063H1.43963H1.38963C1.03958 5.65063 0.889567 5.95067 1.08959 6.35072Z"
          fill="#7AD143"
        />
        <path
          d="M12.8409 12.9015L14.6911 16.2519C14.7911 16.5019 15.0912 16.6519 15.3912 16.6519L19.0416 16.6019C19.2917 16.6019 19.3917 16.4019 19.2917 16.2019L17.4414 12.8515C17.3414 12.6014 17.0414 12.4514 16.7414 12.4514L13.0409 12.5014C12.8409 12.5014 12.6909 12.7014 12.8409 12.9015Z"
          fill="#7AD143"
        />
        <path
          d="M12.8409 0.200023L9.19045 4.80056C8.94042 5.10059 8.94042 5.70066 9.14044 6.0507L12.1908 11.6513C12.3908 12.0514 12.7409 12.1014 13.0409 11.7514L16.7413 7.15083C16.9914 6.85079 16.9914 6.25072 16.7913 5.90068L13.691 0.35004C13.591 0.150017 13.3909 0 13.1909 0C13.0909 0 12.9909 0.0500058 12.8409 0.200023Z"
          fill="#00C2FF"
        />
        <path
          d="M11.9909 12.9515L9.79061 15.7018C9.64059 15.9018 9.59059 16.2018 9.7406 16.4519L11.5908 19.8023C11.6908 20.0523 11.9409 20.0523 12.0909 19.8523L14.2911 17.1019C14.4411 16.9019 14.4912 16.6019 14.3411 16.3519L12.5409 13.0015C12.4909 12.9015 12.3409 12.8015 12.2909 12.8015C12.1409 12.8515 12.0909 12.8515 11.9909 12.9515Z"
          fill="#00C2FF"
        />
      </svg>
    </div>
  );
};

const AppsflyerRound = ({ color, size }: TSvgProps) => {
  return (
    <div
      style={{
        width: `${size}`,
        height: `${size}`,
        borderRadius: '50%',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.08959 6.35072L4.13994 12.0014C4.33997 12.4014 4.84002 12.7014 5.29008 12.7014H11.4908C11.8908 12.7014 12.0909 12.4014 11.8908 12.0014L8.84049 6.35072C8.64046 5.95067 8.1404 5.65063 7.69035 5.65063H1.43963H1.38963C1.03958 5.65063 0.889567 5.95067 1.08959 6.35072Z"
          fill="#7AD143"
        />
        <path
          d="M12.8409 12.9015L14.6911 16.2519C14.7911 16.5019 15.0912 16.6519 15.3912 16.6519L19.0416 16.6019C19.2917 16.6019 19.3917 16.4019 19.2917 16.2019L17.4414 12.8515C17.3414 12.6014 17.0414 12.4514 16.7414 12.4514L13.0409 12.5014C12.8409 12.5014 12.6909 12.7014 12.8409 12.9015Z"
          fill="#7AD143"
        />
        <path
          d="M12.8409 0.200023L9.19045 4.80056C8.94042 5.10059 8.94042 5.70066 9.14044 6.0507L12.1908 11.6513C12.3908 12.0514 12.7409 12.1014 13.0409 11.7514L16.7413 7.15083C16.9914 6.85079 16.9914 6.25072 16.7913 5.90068L13.691 0.35004C13.591 0.150017 13.3909 0 13.1909 0C13.0909 0 12.9909 0.0500058 12.8409 0.200023Z"
          fill="#00C2FF"
        />
        <path
          d="M11.9909 12.9515L9.79061 15.7018C9.64059 15.9018 9.59059 16.2018 9.7406 16.4519L11.5908 19.8023C11.6908 20.0523 11.9409 20.0523 12.0909 19.8523L14.2911 17.1019C14.4411 16.9019 14.4912 16.6019 14.3411 16.3519L12.5409 13.0015C12.4909 12.9015 12.3409 12.8015 12.2909 12.8015C12.1409 12.8515 12.0909 12.8515 11.9909 12.9515Z"
          fill="#00C2FF"
        />
      </svg>
    </div>
  );
};

export const AppsflyerIconRound = ({ size = 40, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={AppsflyerRound}
      solidSvg={AppsflyerRound}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};

export const AppsflyerIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={AppsflyerIconCommon}
      solidSvg={AppsflyerIconCommon}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};

