import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from '../icons-kit/_utils';

const YandexDirectIconCommon = ({ color, size }: TSvgProps) => {
  return (
    <div
      style={{
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4677_21)">
          <g clipPath="url(#clip1_4677_21)">
            <path
              d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10Z"
              fill="#1A4A7F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.36504 19.865C6.36072 19.5358 4.50467 18.6025 3.04504 17.19L12.055 8.92L6.90504 10.17C6.56415 10.2538 6.20998 10.2689 5.86319 10.2144C5.5164 10.1599 5.18392 10.0369 4.88515 9.85264C4.58637 9.66834 4.32727 9.42642 4.12294 9.14097C3.91862 8.85551 3.77316 8.53224 3.69504 8.19C3.5367 7.4936 3.65807 6.76287 4.03304 6.15505C4.40802 5.54724 5.00662 5.11092 5.70004 4.94L16.42 2.335C17.7256 3.42823 18.7304 4.83676 19.3392 6.42708C19.9479 8.0174 20.1405 9.73684 19.8986 11.4224C19.6568 13.108 18.9885 14.7039 17.9571 16.0589C16.9257 17.4139 15.5654 18.483 14.005 19.165C13.975 18.81 14.01 18.44 14.135 18.08L15.835 13.01L8.36504 19.865Z"
              fill="url(#paint0_linear_4677_21)"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_4677_21"
            x1="24.53"
            y1="1.265"
            x2="-9.70495"
            y2="32.68"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFB800" />
            <stop offset="1" stopColor="#FFF11D" />
          </linearGradient>
          <clipPath id="clip0_4677_21">
            <rect width="20" height="20" fill="white" />
          </clipPath>
          <clipPath id="clip1_4677_21">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const YandexDirectIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={YandexDirectIconCommon}
      solidSvg={YandexDirectIconCommon}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
