import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const TreasuryEurOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7092 7.70932C17.7092 10.8625 15.1531 13.4186 11.9999 13.4186C8.84674 13.4186 6.29059 10.8625 6.29059 7.70932C6.29059 4.55615 8.84674 2 11.9999 2C15.1531 2 17.7092 4.55615 17.7092 7.70932ZM16.4092 7.70932C16.4092 10.1445 14.4351 12.1186 11.9999 12.1186C9.56471 12.1186 7.59059 10.1445 7.59059 7.70932C7.59059 5.27412 9.56471 3.3 11.9999 3.3C14.4351 3.3 16.4092 5.27412 16.4092 7.70932Z"
        fill={color}
      />
      <path
        d="M6.24833 16.7094L7.77651 14.9461C8.84107 15.5644 10.0546 15.9544 11.3502 16.0491C11.35 16.0558 11.3499 16.0626 11.3499 16.0693V17.9457C11.3499 18.3046 11.6409 18.5957 11.9999 18.5957C12.3589 18.5957 12.6499 18.3046 12.6499 17.9457V16.0693C12.6499 16.0609 12.6498 16.0526 12.6494 16.0443C13.9775 15.9372 15.218 15.5196 16.2981 14.8642L17.7515 16.5413C17.9866 16.8126 18.3971 16.8419 18.6684 16.6068C18.9397 16.3717 18.969 15.9612 18.7339 15.6899L16.9815 13.6678C16.9663 13.645 16.9496 13.6229 16.9312 13.6017C16.6984 13.3322 16.2938 13.3027 16.0018 13.5065C14.8585 14.3039 13.468 14.7716 11.9684 14.7716C10.5314 14.7716 9.19472 14.3422 8.07964 13.6047C7.80533 13.4233 7.43992 13.4429 7.20746 13.664C7.1519 13.6981 7.10055 13.7411 7.05562 13.7929L5.26592 15.858C5.03082 16.1293 5.06015 16.5398 5.33143 16.7749C5.60272 17.01 6.01323 16.9807 6.24833 16.7094Z"
        fill={color}
      />
      <path
        d="M4.11226 19.9507C4.83023 19.9507 5.41226 19.3687 5.41226 18.6507C5.41226 17.9327 4.83023 17.3507 4.11226 17.3507C3.39429 17.3507 2.81226 17.9327 2.81226 18.6507C2.81226 19.3687 3.39429 19.9507 4.11226 19.9507Z"
        fill={color}
      />
      <path
        d="M13.3 20.7C13.3 21.418 12.7179 22 12 22C11.282 22 10.7 21.418 10.7 20.7C10.7 19.9821 11.282 19.4 12 19.4C12.7179 19.4 13.3 19.9821 13.3 20.7Z"
        fill={color}
      />
      <path
        d="M21.188 18.6507C21.188 19.3687 20.6059 19.9507 19.8879 19.9507C19.17 19.9507 18.588 19.3687 18.588 18.6507C18.588 17.9327 19.17 17.3507 19.8879 17.3507C20.6059 17.3507 21.188 17.9327 21.188 18.6507Z"
        fill={color}
      />
      <path
        d="M9.3944 7.46988C9.38248 7.57854 9.37635 7.68895 9.37635 7.80078C9.37635 7.91262 9.38248 8.02303 9.3944 8.1317H8.92449C8.69278 8.1317 8.50495 8.31945 8.50495 8.55105C8.50495 8.78265 8.69278 8.97041 8.92449 8.97041H9.61301C10.0691 10.0461 11.1352 10.8008 12.3777 10.8008C13.1386 10.8008 13.8342 10.5172 14.363 10.0507C14.5367 9.89741 14.5533 9.63241 14.4 9.45877C14.2466 9.28512 13.9815 9.2686 13.8078 9.42186C13.4264 9.75836 12.9263 9.96207 12.3777 9.96207C11.6147 9.96207 10.944 9.56703 10.5591 8.97041H12.6088C12.8405 8.97041 13.0283 8.78265 13.0283 8.55105C13.0283 8.31945 12.8405 8.1317 12.6088 8.1317H10.2406C10.224 8.02381 10.2154 7.9133 10.2154 7.80078C10.2154 7.68827 10.224 7.57776 10.2406 7.46988L12.6088 7.46988C12.8405 7.46988 13.0283 7.28212 13.0283 7.05052C13.0283 6.81892 12.8405 6.63117 12.6088 6.63117H10.5591C10.944 6.03454 11.6147 5.63949 12.3777 5.63949C12.9263 5.63949 13.4264 5.8432 13.8078 6.1797C13.9815 6.33296 14.2466 6.31644 14.4 6.1428C14.5533 5.96916 14.5367 5.70415 14.363 5.55089C13.8342 5.08434 13.1386 4.80078 12.3777 4.80078C11.1352 4.80078 10.0691 5.55542 9.61301 6.63117H8.92449C8.69278 6.63117 8.50495 6.81892 8.50495 7.05052C8.50495 7.28213 8.69278 7.46988 8.92449 7.46988H9.3944Z"
        fill={color}
        strokeWidth="0.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const TreasuryEurIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={TreasuryEurOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
