import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from '../icons-kit/_utils';

const YandexMetricIconCommon = ({ color, size }: TSvgProps) => {
  return (
    <div
      style={{
        width: `${size}`,
        height: `${size}`,
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4677_50)">
          <circle cx="10" cy="10" r="10" fill="#3364DB" />
          <mask
            id="mask0_4677_50"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
          >
            <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_4677_50)">
            <rect x="13.75" width="6.25" height="20" fill="url(#paint0_linear_4677_50)" />
            <path
              d="M6.25 8.25C6.25 7.14543 7.14543 6.25 8.25 6.25H13.75V20H6.25V8.25Z"
              fill="url(#paint1_linear_4677_50)"
            />
            <path d="M-1.25 12.5H6.25V20H-1.25V12.5Z" fill="#FF304E" />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_4677_50"
            x1="16.875"
            y1="0"
            x2="16.875"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE719" />
            <stop offset="1" stopColor="#FFD30E" />
          </linearGradient>
          <linearGradient id="paint1_linear_4677_50" x1="10" y1="6.25" x2="10" y2="20" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00B2FF" />
            <stop offset="1" stopColor="#1399FF" />
          </linearGradient>
          <clipPath id="clip0_4677_50">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const YandexMetricIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={YandexMetricIconCommon}
      solidSvg={YandexMetricIconCommon}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
