import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const ShapeIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5417 5.0588L10.5417 3.02922C10.5417 2.73006 10.2992 2.48755 10 2.48755C9.70085 2.48755 9.45833 2.73006 9.45833 3.02922V5.0588C8.33804 5.30627 7.5 6.30525 7.5 7.49996C7.5 8.88067 8.61929 9.99996 10 9.99996C11.3807 9.99996 12.5 8.88067 12.5 7.49996C12.5 6.30525 11.662 5.30627 10.5417 5.0588ZM10 6.08329C10.7824 6.08329 11.4167 6.71756 11.4167 7.49996C11.4167 8.28236 10.7824 8.91663 10 8.91663C9.2176 8.91663 8.58333 8.28236 8.58333 7.49996C8.58333 6.71756 9.2176 6.08329 10 6.08329Z"
        fill={color}
      />
      <path
        d="M10.5417 16.9683C10.5417 17.2674 10.2992 17.5099 10 17.5099C9.70085 17.5099 9.45833 17.2674 9.45833 16.9683V12.0809C9.45833 11.7817 9.70085 11.5392 10 11.5392C10.2992 11.5392 10.5417 11.7817 10.5417 12.0809V16.9683Z"
        fill={color}
      />
      <path
        d="M5 8.47668C5.29915 8.47668 5.54167 8.23417 5.54167 7.93501L5.54167 3.02923C5.54167 2.73008 5.29915 2.48756 5 2.48756C4.70085 2.48756 4.45833 2.73008 4.45833 3.02923V7.93501C4.45833 8.23417 4.70085 8.47668 5 8.47668Z"
        fill={color}
      />
      <path
        d="M15.5417 3.02924C15.5417 2.73009 15.2992 2.48757 15 2.48757C14.7008 2.48757 14.4583 2.73009 14.4583 3.02924V7.93742C14.4583 8.23657 14.7008 8.47909 15 8.47909C15.2992 8.47909 15.5417 8.23657 15.5417 7.93742V3.02924Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5417 14.9411C16.662 14.6937 17.5 13.6947 17.5 12.5C17.5 11.1192 16.3807 9.99996 15 9.99996C13.6193 9.99996 12.5 11.1192 12.5 12.5C12.5 13.6947 13.338 14.6937 14.4583 14.9411V16.9683C14.4583 17.2675 14.7008 17.51 15 17.51C15.2992 17.51 15.5417 17.2675 15.5417 16.9683V14.9411ZM16.4167 12.5C16.4167 13.2824 15.7824 13.9166 15 13.9166C14.2176 13.9166 13.5833 13.2824 13.5833 12.5C13.5833 11.7176 14.2176 11.0833 15 11.0833C15.7824 11.0833 16.4167 11.7176 16.4167 12.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 17.51C5.29915 17.51 5.54167 17.2674 5.54167 16.9683V14.9387C6.66196 14.6913 7.5 13.6923 7.5 12.4976C7.5 11.1169 6.38071 9.99757 5 9.99757C3.61929 9.99757 2.5 11.1169 2.5 12.4976C2.5 13.6923 3.33804 14.6913 4.45833 14.9387V16.9683C4.45833 17.2674 4.70085 17.51 5 17.51ZM6.41667 12.4976C6.41667 11.7152 5.7824 11.0809 5 11.0809C4.2176 11.0809 3.58333 11.7152 3.58333 12.4976C3.58333 13.28 4.2176 13.9142 5 13.9142C5.7824 13.9142 6.41667 13.28 6.41667 12.4976Z"
        fill={color}
      />
    </svg>
  );
};

const ShapeIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5417 5.0588L10.5417 3.02922C10.5417 2.73006 10.2992 2.48755 10 2.48755C9.70085 2.48755 9.45833 2.73006 9.45833 3.02922V5.0588C8.33804 5.30627 7.5 6.30525 7.5 7.49996C7.5 8.88067 8.61929 9.99996 10 9.99996C11.3807 9.99996 12.5 8.88067 12.5 7.49996C12.5 6.30525 11.662 5.30627 10.5417 5.0588ZM10 6.08329C10.7824 6.08329 11.4167 6.71756 11.4167 7.49996C11.4167 8.28236 10.7824 8.91663 10 8.91663C9.2176 8.91663 8.58333 8.28236 8.58333 7.49996C8.58333 6.71756 9.2176 6.08329 10 6.08329Z"
        fill={color}
      />
      <path
        d="M10.5417 16.9683C10.5417 17.2674 10.2992 17.5099 10 17.5099C9.70085 17.5099 9.45833 17.2674 9.45833 16.9683V12.0809C9.45833 11.7817 9.70085 11.5392 10 11.5392C10.2992 11.5392 10.5417 11.7817 10.5417 12.0809V16.9683Z"
        fill={color}
      />
      <path
        d="M5 8.47668C5.29915 8.47668 5.54167 8.23417 5.54167 7.93501L5.54167 3.02923C5.54167 2.73008 5.29915 2.48756 5 2.48756C4.70085 2.48756 4.45833 2.73008 4.45833 3.02923V7.93501C4.45833 8.23417 4.70085 8.47668 5 8.47668Z"
        fill={color}
      />
      <path
        d="M15.5417 3.02924C15.5417 2.73009 15.2992 2.48757 15 2.48757C14.7008 2.48757 14.4583 2.73009 14.4583 3.02924V7.93742C14.4583 8.23657 14.7008 8.47909 15 8.47909C15.2992 8.47909 15.5417 8.23657 15.5417 7.93742V3.02924Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5417 14.9411C16.662 14.6937 17.5 13.6947 17.5 12.5C17.5 11.1192 16.3807 9.99996 15 9.99996C13.6193 9.99996 12.5 11.1192 12.5 12.5C12.5 13.6947 13.338 14.6937 14.4583 14.9411V16.9683C14.4583 17.2675 14.7008 17.51 15 17.51C15.2992 17.51 15.5417 17.2675 15.5417 16.9683V14.9411ZM16.4167 12.5C16.4167 13.2824 15.7824 13.9166 15 13.9166C14.2176 13.9166 13.5833 13.2824 13.5833 12.5C13.5833 11.7176 14.2176 11.0833 15 11.0833C15.7824 11.0833 16.4167 11.7176 16.4167 12.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 17.51C5.29915 17.51 5.54167 17.2674 5.54167 16.9683V14.9387C6.66196 14.6913 7.5 13.6923 7.5 12.4976C7.5 11.1169 6.38071 9.99757 5 9.99757C3.61929 9.99757 2.5 11.1169 2.5 12.4976C2.5 13.6923 3.33804 14.6913 4.45833 14.9387V16.9683C4.45833 17.2674 4.70085 17.51 5 17.51ZM6.41667 12.4976C6.41667 11.7152 5.7824 11.0809 5 11.0809C4.2176 11.0809 3.58333 11.7152 3.58333 12.4976C3.58333 13.28 4.2176 13.9142 5 13.9142C5.7824 13.9142 6.41667 13.28 6.41667 12.4976Z"
        fill={color}
      />
    </svg>
  );
};

export const ShapeIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ShapeIconOutlined}
      solidSvg={ShapeIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
